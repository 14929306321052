import useLocalStorage from "hooks/useLocalStorage"
import useNotification from "hooks/useNotification"
import { useEffect, useMemo } from "react"
import {
	Outlet,
	useLocation,
	useNavigate,
	useSearchParams
} from "react-router-dom"
import { AuthContext } from "../index"
import ROLE_TYPE_LIST from "shared/roleTypeList"

const AuthProvider = ({ children }) => {
	const [user, setUser] = useLocalStorage("user", null)
	const location = useLocation()
	const [searchParams, setSearchParams] = useSearchParams()
	const navigate = useNavigate()
	const sendNotification = useNotification()

	const login = async (data) => {
		sendNotification({
			msg: "Siz tizimga muvaffaqiyatli kirdingiz!",
			variant: "success"
		})
		setUser(data)
	}

	useEffect(() => {
		if (user && user.user) {
			if (user && user?.user?.role) {
				if (searchParams.has("from")) {
					navigate(searchParams.get("from"), { replace: true })
				} else {
					if (user?.user?.role === ROLE_TYPE_LIST.ADMIN.code) {
						if (location.pathname.includes("admin")) {
							navigate(location.pathname, { replace: true })
						} else {
							navigate("/admin/dashboard", { replace: true })
						}
					} else if (user?.user?.role === ROLE_TYPE_LIST.ACCOUNTER.code) {
						if (location.pathname.includes("accounter")) {
							navigate(location.pathname, { replace: true })
						} else {
							navigate("/accounter/payment", { replace: true })
						}
					} else if (user?.user?.role === ROLE_TYPE_LIST.MANAGER.code) {
						if (location.pathname.includes("manager")) {
							navigate(location.pathname, { replace: true })
						} else {
							navigate("/manager/dashboard", { replace: true })
						}
					} else if (user?.user?.role === ROLE_TYPE_LIST.OPERATOR.code) {
						if (location.pathname.includes("operator")) {
							navigate(location.pathname, { replace: true })
						} else {
							navigate("/operator/lead", { replace: true })
						}
					} else if (user?.user?.role === ROLE_TYPE_LIST.CASHER.code) {
						if (location.pathname.includes("kassir")) {
							navigate(location.pathname, { replace: true })
						} else {
							navigate("/kassir/accepts", { replace: true })
						}
					} else {
						navigate("/admin/dashboard", { replace: true })
					}
				}
			} else {
				navigate("/admin/dashboard", { replace: true })
			}
		}
	}, [user])

	const logout = async () => {
		setUser(null)
		navigate("/login", { replace: true })
	}

	const value = useMemo(
		() => ({
			user,
			login,
			logout
		}),
		[user]
	)

	return (
		<AuthContext.Provider value={[value, setUser]}>
			<Outlet />
		</AuthContext.Provider>
	)
}

export default AuthProvider
