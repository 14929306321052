import * as React from "react"
import Slider from "@mui/material/Slider"
import { styled } from "@mui/material/styles"
import { colors } from "shared/colors"
import { NumericFormat } from "react-number-format"
import { useState } from "react"

/* const marks = [
	{ value: 2000000 },
	{ value: 2500000 },
	{ value: 3000000 },
	{ value: 3500000 },
	{ value: 4000000 },
	{ value: 4500000 },
	{ value: 5000000 },
	{ value: 5700000 },
	{ value: 6300000 }
] */

const PrettoSlider = styled(Slider)({
	color: colors.baseColor,
	height: 3,
	"& .MuiSlider-track": {
		border: "none"
	},
	"& .MuiSlider-thumb": {
		height: 20,
		width: 20,
		backgroundColor: "#fff",
		border: "2px solid currentColor",
		"&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
			boxShadow: "inherit"
		},
		"&:before": {
			display: "none"
		}
	},
	"& .MuiSlider-valueLabel": {
		fontSize: 12,
		backgroundColor: colors.baseColorLight,
		borderRadius: "4px",
		lineHeight: 1.2,
		paddingRight: "4px",
		paddingLeft: "4px"
		/* lineHeight: 1.2,
		fontSize: 12,
		background: "unset",
		padding: 0,
		width: 32,
		height: 32,
		borderRadius: "50% 50% 50% 0",
		backgroundColor: colors.baseColorLight,
		transformOrigin: "bottom left",
		transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
		"&:before": { display: "none" },
		"&.MuiSlider-valueLabelOpen": {
			transform: "translate(50%, -100%) rotate(-45deg) scale(1)"
		},
		"& > *": {
			transform: "rotate(45deg)"
		} */
	},
	"& .MuiSlider-mark": {
		backgroundColor: colors.base1,
		height: 16,
		width: 2,
		"&.MuiSlider-markActive": {
			opacity: 1,
			backgroundColor: "currentColor"
		}
	}
})

export default function CustomSlider({
	value,
	handleChange,
	label = "",
	marks = [],
	min,
	max,
	customFormat = false,
	suffix,
	allowNegative = false
}) {

	return (
		<React.Fragment>
			<div className="text-center">{label}</div>
			<PrettoSlider
				getAriaLabel={() => "Minimum distance shift"}
				min={min}
				max={max}
				valueLabelDisplay="on"
				marks={marks}
				step={null}
				value={value}
				onChange={handleChange}
				disableSwap
				getAriaValueText={(value) => (
					customFormat ? (<span>{value}{suffix}</span>) : <NumericFormat
						value={value}
						displayType={"text"}
						allowNegative={allowNegative}
						thousandSeparator={" "}
						decimalScale={3}
						className="bg-transparent whitespace-nowrap tracking-tighter"
						suffix={suffix}
					/>
				)}
				valueLabelFormat={(value) => (
					customFormat ? (<span>{value}{suffix}</span>) : <NumericFormat
						value={value}
						displayType={"text"}
						allowNegative={allowNegative}
						thousandSeparator={" "}
						decimalScale={3}
						className="bg-transparent whitespace-nowrap tracking-tighter"
						suffix={suffix}
					/>
				)}
			/>
		</React.Fragment>
	)
}
