import * as React from 'react';

import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';

export default function BasicDateRangePicker({ getDates, ...props }) {
  const [dates, setDates] = React.useState({
    from: null,
    till: null,
  });
  return (
    <LocalizationProvider
      dateAdapter={AdapterMoment}
      localeText={{ start: 'Check-in', end: 'Check-out' }}
    >
      <DatePicker
        clearable='true'
        value={dates?.from}
        inputFormat='YYYY-MM-DD'
        onChange={(newValue) => {
          setDates({
            ...dates,
            from: newValue || null,
          });
          if (!newValue) {
            getDates('from', null);
          } else if (JSON.stringify(newValue?._d) !== 'null') {
            getDates(
              'from',
              JSON.stringify(moment(newValue?._d).format('YYYY-MM-DD'))
            );
          } else if (newValue?._i?.length === 10) {
            getDates('from', JSON.stringify(newValue?._i));
          }
        }}
        renderInput={(startProps) => {
          return (
            <React.Fragment>
              <TextField
                {...startProps}
                component='form'
                label='Sanadan'
                sx={{
                  '& .MuiOutlinedInput-notchedOutline ': {
                    border: '1px solid black !important',
                  },
                  '& label': {
                    color: 'black !important',
                  },
                }}
              />
            </React.Fragment>
          );
        }}
        {...props}
      />
      <span style={{ display: 'inline-block', width: '24px' }}></span>
      <DatePicker
        value={dates?.till}
        inputFormat='YYYY-MM-DD'
        onChange={(newValue) => {
          setDates({
            ...dates,
            till: newValue || null,
          });
          if (!newValue) {
            getDates('till', null);
          } else if (JSON.stringify(newValue?._d) !== 'null') {
            getDates(
              'till',
              JSON.stringify(moment(newValue?._d).format('YYYY-MM-DD'))
            );
          } else if (newValue?._i?.length === 10) {
            getDates('till', JSON.stringify(newValue?._i));
          }
        }}
        renderInput={(startProps) => (
          <React.Fragment>
            <TextField
              {...startProps}
              component='form'
              label='Sanagacha'
              sx={{
                '& .MuiOutlinedInput-notchedOutline ': {
                  border: '1px solid black !important',
                },
                '& label': {
                  color: 'black !important',
                },
              }}
            />
          </React.Fragment>
        )}
        {...props}
      />
    </LocalizationProvider>
  );
}
