import { TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { Box } from '@mui/system';
import React, { Fragment } from 'react';
import { visuallyHidden } from '@mui/utils';
import { thousandsSeparator } from '../../../../utils/formatters';

const SimpleTableHead = (props) => {
  const {
    order,
    orderBy,
    onRequestSort,
    headCells,
    isFilterable,
    contractcount,
    countpayment,
    total,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, headCellIndex) => {
          let lable2 =
            headCell?.label === 'Jami shartnomalar'
              ? `Jami shartnomalar: ${contractcount} ta`
              : headCell?.label === "Jami to'lovlar"
              ? `Jami to'lovlar: $ ${thousandsSeparator(
                  total
                )} pul (${countpayment} ta)`
              : headCell?.label;
          return (
            <Fragment key={'head-cell-' + headCell.code}>
              {isFilterable ? (
                <TableCell
                  align={headCell.numeric ? 'right' : 'left'}
                  padding={headCell.disablePadding ? 'none' : 'normal'}
                  sortDirection={orderBy === headCell.code ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === headCell.code}
                    direction={orderBy === headCell.code ? order : 'asc'}
                    onClick={createSortHandler(headCell.code)}
                    hideSortIcon={orderBy === headCell.code}
                  >
                    <div
                      dangerouslySetInnerHTML={{ __html: headCell?.label }}
                    />
                    {orderBy === headCell.code ? (
                      <Box component='span' sx={visuallyHidden}>
                        {order === 'desc'
                          ? 'sorted descending'
                          : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ) : (
                <TableCell
                  align={headCell.numeric ? 'right' : 'left'}
                  padding={headCell.disablePadding ? 'none' : 'normal'}
                >
                  <div dangerouslySetInnerHTML={{ __html: lable2 }} />
                </TableCell>
              )}
            </Fragment>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

export default SimpleTableHead;
