const REPAIR_TYPE = {
	NOT_REPAIRED: {
		code: "0",
		label: "Ta'mirsiz"
	},
	REPAIRED: {
		code: "1",
		label: "Ta'mirli"
	}
}

module.exports = REPAIR_TYPE
