import {
	Button,
	ButtonBase,
	Checkbox,
	FormControl,
	FormControlLabel,
	Tab,
	Tabs,
	TextField,
	ToggleButton,
	ToggleButtonGroup
} from "@mui/material"
import SimpleSelectField from "components/ui/simple-fields/select/SimpleSelectField"
import { Fragment, useEffect, useState } from "react"
import { motion } from "framer-motion"
import { fadeUp, stepperItem } from "utils/motion"
import CurrencyFormat from "components/ui/text-formats/CurrencyFormat"
import { useFormik } from "formik"
import * as yup from "yup"
import useNotification from "hooks/useNotification"
import { NumericFormat } from "react-number-format"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import moment from "moment"
import StepThreeTabOne from "./step-three-tabs/StepThreeTabOne"
import StepThreeTabTwo from "./step-three-tabs/StepThreeTabTwo"
import useCurrency from "hooks/useCurrency"
import CurrencySubContent from "components/ui/text-formats/CurrencySubContent"
import useAxiosPrivate from "hooks/useAxiosPrivate"

const validationSchema = yup.object({
	month: yup
		.number()
		.default(1)
		.required("To'lov muddati(necha oyda)ni kiritish majburiy!"),
	date: yup
		.date()
		.nullable()
		.typeError("Vaqti to'g'ri bo'lishi kerak!")
		.min(
			new Date("Sat Jan 01 2000 00:00:00 GMT+0500"),
			"Sana 2000-yildan katta bo'lishi lozim!"
		)
		.required("Birinchi to'lov sanasini kiritish majburiy!"),
	startDate: yup
		.date()
		.nullable()
		.typeError("Vaqti to'g'ri bo'lishi kerak!")
		.min(
			new Date("Sat Jan 01 2000 00:00:00 GMT+0500"),
			"Sana 2000-yildan katta bo'lishi lozim!"
		)
		.required("Boshlang'ich to'lov sanasini kiritish majburiy!"),
	sum: yup
		.number()
		.default(0)
		.required("Shartnoma summasini kiritish majburiy!"),
	left: yup.number().required("Qoldiq summa kiritish majburiy!"),
	discount: yup.number().default(0).optional(),
	interval: yup
		.number()
		.default(1)
		.required("Vaqt oralig'ini kiritish majburiy!"),
	valute: yup
		.number()
		.default(1)
		.optional(),
	startPrice: yup
		.number()
		.default(0)
		.required("Boshlang'ich to'lov miqdorini kiritish majburiy!"),
	price: yup
		.number()
		.default(0)
		.required(
			<span>
				1 m<sup>2</sup> narxini kiritish majburiy!
			</span>
		),
	comment: yup.string().optional(),
	contractName: yup.string().required("Shartnoma raqamini kiritish majburiy!")
})

const StepThree = ({ appear, direction, next, back, homeData, setData }) => {
	const [repairData, setRepairData] = useState("")
	const [isRepaired, setIsRepaired] = useState("0")
	const [paymentDataList, setPaymentDataList] = useState([])
	const [paymentDataList2, setPaymentDataList2] = useState([])
	const [calculateByPrice, setCalculateByPrice] = useState(false)
	const [tabValue, setTabValue] = useState("automatic")
	const { currencyData, updateCurrencyData } = useCurrency()
	const [selectedCurrency, setSelectedCurrency] = useState("0")
	const [selectedCurrency2, setSelectedCurrency2] = useState("0")
	const sendNotification = useNotification()
	const axiosPrivate = useAxiosPrivate()
	const [contractTypes, setContractTypes] = useState([])
	const [contractType, setContractType] = useState(0)

	const formik = useFormik({
		initialValues: {
			month: "1",
			date: "",
			startDate: "",
			sum: "",
			left: "",
			discount: "0",
			interval: "1",
			valute: 1,
			startPrice: "0",
			price: "",
			comment: "",
			contractName: ""
		},
		validationSchema: validationSchema,
		onSubmit: (values) => {}
	})

	const handleNext = () => {
		if (!formik.isValid) {
			formik.handleSubmit()
		}
		let allSumPaymentDataList = paymentDataList.reduce(
			(acc, curr) => acc + curr?.sum,
			0
		)
		if(selectedCurrency === "0" && selectedCurrency2 === "1"){
			allSumPaymentDataList *= formik.values.valute
		} 
		if(selectedCurrency === "1" && selectedCurrency2 === "0"){
			allSumPaymentDataList /= formik.values.valute
		}
		let allSumPaymentDataList2 = paymentDataList2.reduce(
			(acc, curr) => acc + curr?.sum,
			0
		)
		let difference = Math.abs(allSumPaymentDataList-formik.values.left)
		if(selectedCurrency === "0" && selectedCurrency2 === "1"){
			difference /= formik.values.valute
			difference /= formik.values.month
		}

		if (
			repairData &&
			formik.isValid &&
			((tabValue === "automatic" &&
				paymentDataList.length === parseInt(formik.values.month) &&
				(difference < 10)) ||
				(tabValue === "manually" &&
					paymentDataList2.length === parseInt(formik.values.month) &&
					allSumPaymentDataList2 === formik.values.left))
		) {
			setData({
				price: formik.values.price,
				sum: formik.values.sum, // this filed in used for show contract sum in ContractAdd.jsx, not in StepFour.jsx
				start_price: formik.values.startPrice,
				startDate: formik.values.startDate,
				month: formik.values.month,
				payments:
					tabValue === "automatic"
						? paymentDataList
						: tabValue === "manually"
						? paymentDataList2
						: [],
				discount: formik.values.discount,
				name: formik.values.contractName,
				comment: formik.values.comment,
				isrepaired: isRepaired,
				isvalute: selectedCurrency,
				valute: formik.values.valute,
				type: contractType
			})
			next()
		} else if (!repairData) {
			sendNotification({
				msg: "Iltimos uyning ta'mir turini tanlang!",
				variant: "warning"
			})
		} else if (
			(tabValue === "automatic" &&
				paymentDataList.length !== parseInt(formik.values.month)) ||
			(tabValue === "manually" &&
				paymentDataList2.length !== formik.values.month)
		) {
			sendNotification({
				msg: "Jadvaldagi to'lovlar soni kreditni qaytarish muddati bilan mos emas!",
				variant: "warning"
			})
		} else if (
			(tabValue === "automatic" &&
				allSumPaymentDataList !== formik.values.left) ||
			(tabValue === "manually" && allSumPaymentDataList2 !== formik.values.left)
		) {
			sendNotification({
				msg: "Qoldiq summada xatolik bor!",
				variant: "warning"
			})
		}
	}

	const handleRepairType = (type, data) => {
		Array.from(document.getElementsByClassName("repair-type")).forEach((item) =>
			item.classList.remove("item-selected")
		)
		if (type === "repaired") {
			setIsRepaired("1")
			document.getElementById("repaired").classList.add("item-selected")
		} else if (type === "noRepaired") {
			setIsRepaired("0")
			document.getElementById("non-repaired").classList.add("item-selected")
		}
		formik.setFieldValue("price", data.price, true)
		formik.setFieldValue(
			"sum",
			parseFloat(data.square) * parseFloat(data.price),
			true
		)
		formik.setFieldValue(
			"left",
			parseFloat(data.square) * parseFloat(data.price) -
				formik.values.startPrice -
				formik.values.discount,
			true
		)
		setRepairData(data)
	}

	const getPaymentData = () => {
		if (
			!isNaN(formik.values.month) &&
			!isNaN(formik.values.left) &&
			formik.values.date &&
			!isNaN(formik.values.interval)
		) {
			let arr = []
			for (let i = 1; i <= formik.values.month; i++) {
				arr.push({
					id: i,
					date: moment(formik.values.date)
						.add(formik.values.interval * (i - 1), "month")
						.format("YYYY-MM-DD"),
					left:
						formik.values.left -
						Math.floor(formik.values.left / formik.values.month) * (i - 1),
					sum: Math.floor(formik.values.left / formik.values.month)
				})
			}
			if (arr.length > 1) {
				arr[arr.length - 1] = {
					...arr[arr.length - 1],
					sum: arr[arr.length - 1].left
				}
			}
			let factor = 1
			if(selectedCurrency2 === '1' && selectedCurrency === '0'){
				factor = 1 / formik.values.valute
			}

			let left = 0, lastSum = 0, currentSum = 0, updatedData = []
		
			for(let i = 0; i < arr.length; i++){
				if(i == 0){
					left = Math.round(arr[i].left * factor);
					lastSum = Math.round(arr[i].sum * factor);
				} else {
					left = left - lastSum
				}
				if(i == arr.length-1){
					currentSum = left
				} else {
					currentSum = Math.round(arr[i].sum * factor);
				}
	
				updatedData.push({
					...arr[i],
					left: left,
					sum: currentSum,
					  isvalute: selectedCurrency2
				})
			}
			setPaymentDataList(updatedData)
		}
	}

	const handleCurrencyChange = (value) => {
		if(selectedCurrency === value){
			return
		}
		setSelectedCurrency(value)
		setSelectedCurrency2(value)
		if (formik.values.valute && formik.values.valute > 0) {
			if (value === "1") {
				formik.setFieldValue(
					"price",
					parseFloat(
						parseFloat((formik.values.price || "0") / formik.values.valute
					).toFixed(1)),
					true
				)
				formik.setFieldValue(
					"sum",
					parseFloat(
						parseFloat((formik.values.sum || "0") / formik.values.valute
					).toFixed(1)),
					true
				)
				formik.setFieldValue(
					"startPrice",
					parseFloat(
						parseFloat((formik.values.startPrice || "0") / formik.values.valute
					).toFixed(1)),
					true
				)
				formik.setFieldValue(
					"left",
					parseFloat(
						parseFloat((formik.values.left || "0") / formik.values.valute
					).toFixed(1)),
					true
				)
				formik.setFieldValue(
					"discount",
					parseFloat(
						parseFloat((formik.values.discount || "0") / formik.values.valute
					).toFixed(1)),
					true
				)
			} else if (value === "0") {
				formik.setFieldValue(
					"price",
					parseFloat((formik.values.price || "0") * formik.values.valute),
					true
				)
				formik.setFieldValue(
					"sum",
					parseFloat((formik.values.sum || "0") * formik.values.valute),
					true
				)
				formik.setFieldValue(
					"startPrice",
					parseFloat(
						(formik.values.startPrice || "0") * formik.values.valute
					),
					true
				)
				formik.setFieldValue(
					"left",
					parseFloat((formik.values.left || "0") * formik.values.valute),
					true
				)
				formik.setFieldValue(
					"discount",
					parseFloat(
						(formik.values.discount || "0") * formik.values.valute
					),
					true
				)
			}
		}
	}
	const handleCurrencyChange2 = (value) => {
		if(selectedCurrency2 !== value){
			setSelectedCurrency2(value)		
		}
	}
	const handleContractType = (value) => {
		setContractType(value)
	}
	useEffect(() => {
		convertSumToUsd()
	 }, [selectedCurrency2]);
	const convertSumToUsd = () => {
		let factor = 1;

		if (selectedCurrency === "0") {
		  if (selectedCurrency2 === '0') {
			factor = formik.values.valute;
		  } else {
			factor = 1 / formik.values.valute;
		  }
		} else {
			if (selectedCurrency2 === '0') {
		  		factor = formik.values.valute;
			} else {
				factor = 1 / formik.values.valute;
			}
		}
		let left = 0, lastSum = 0, currentSum = 0, updatedData = []
		for(let i = 0; i < paymentDataList.length; i++){
			if(i == 0){
				left = Math.round(paymentDataList[i].left * factor);
				lastSum = Math.round(paymentDataList[i].sum * factor);
			} else {
				left = left - lastSum
			}
			if(i == paymentDataList.length-1){
				currentSum = left
			} else {
				currentSum = Math.round(paymentDataList[i].sum * factor);
			}

			updatedData.push({
				...paymentDataList[i],
				left: left,
				sum: currentSum,
		  		isvalute: selectedCurrency2
			})

		}
		setPaymentDataList(updatedData);
	  }

	useEffect(() => {
		getPaymentData()
	}, [formik.values])

	useEffect(() => {
		if (homeData) {
			formik.setFieldValue(
				"contractName",
				`${homeData?.blocks?.objects?.name} ${homeData?.blocks?.name} ${homeData?.number}-хонадон`,
				true
			)
			setSelectedCurrency(homeData?.isvalute || "0")
		}
	}, [homeData])

	useEffect(() => {
		axiosPrivate.get("dictionary/contracttypes")
		.then((result) => {
			setContractTypes(result.data.data.map((item)=>{return {code:item.id, label:item.name}}));
		})
		updateCurrencyData();
		if(formik.values.valute === 1){
			formik.values.valute = currencyData.sum
		}

	  }, []);

	return (
		<motion.div
			variants={stepperItem({
				direction: direction,
				duration: 0
			})}
			initial="hidden"
			animate={appear ? "show" : "hidden"}
		>
			<div className="component-add-wrapper">
				<div className="component-add-body overflow-y-auto h-full">
					<div className="home-repair-type">
						<div className="title">Shartnoma to'lovlari</div>
						<div className="repair-types">
							<ButtonBase
								className="repair-type repair-type-non-repaired !mr-2"
								id="non-repaired"
								onClick={() =>
									handleRepairType("noRepaired", {
										price: homeData?.norepaired,
										square: homeData?.square
									})
								}
							>
								<div className="flex items-center justify-between w-full">
									<span>Ta'mirsiz</span>
									<span>
										1 m<sup>2</sup>:{" "}
										<CurrencyFormat
											value={homeData?.norepaired}
											suffix={homeData?.isvalute === "1" ? " $" : " UZS"}
										/>
									</span>
								</div>
								{formik.values.valute &&
									formik.values.valute > 0 &&
									homeData?.isvalute === "1" && (
										<div className="price-by-currency-wrapper">
											=
											<CurrencyFormat
												value={parseFloat(
													formik.values.valute * parseFloat(homeData?.norepaired)
												)}
												className="ml-1"
											/>
										</div>
									)}
								<div className="flex items-center justify-between w-full mt-2">
									<span>
										{homeData?.square} m<sup>2</sup>
									</span>
									<span>
										{homeData?.square && homeData?.norepaired && (
											<CurrencyFormat
												value={homeData?.square * homeData?.norepaired}
												suffix={homeData?.isvalute === "1" ? " $" : " UZS"}
											/>
										)}
									</span>
								</div>
								{formik.values.valute &&
									formik.values.valute > 0 &&
									homeData?.isvalute === "1" && (
										<div className="price-by-currency-wrapper">
											=
											<CurrencyFormat
												value={parseFloat(
													formik.values.valute *
														parseFloat(homeData?.square * homeData?.norepaired)
												)}
												className="ml-1"
											/>
										</div>
									)}
							</ButtonBase>
							<ButtonBase
								className="repair-type repair-type-repaired !ml-2"
								id="repaired"
								onClick={() =>
									handleRepairType("repaired", {
										price: homeData?.repaired,
										square: homeData?.square
									})
								}
							>
								<div className="flex items-center justify-between w-full">
									<span>Ta'mirli</span>
									<span>
										1 m<sup>2</sup>:{" "}
										<CurrencyFormat
											value={homeData?.repaired}
											suffix={homeData?.isvalute === "1" ? " $" : " UZS"}
										/>
									</span>
								</div>
								{formik.values.valute &&
									formik.values.valute > 0 &&
									homeData?.isvalute === "1" && (
										<div className="price-by-currency-wrapper">
											=
											<CurrencyFormat
												value={parseFloat(
													formik.values.valute * parseFloat(homeData?.repaired)
												)}
												className="ml-1"
											/>
										</div>
									)}
								<div className="flex items-center justify-between w-full mt-2">
									<span>
										{homeData?.square} m<sup>2</sup>
									</span>
									<span>
										{homeData?.square && homeData?.repaired && (
											<CurrencyFormat
												value={homeData?.square * homeData?.repaired}
												suffix={homeData?.isvalute === "1" ? " $" : " UZS"}
											/>
										)}
									</span>
								</div>
								{formik.values.valute &&
									formik.values.valute > 0 &&
									homeData?.isvalute === "1" && (
										<div className="price-by-currency-wrapper">
											=
											<CurrencyFormat
												value={parseFloat(
													formik.values.valute *
														parseFloat(homeData?.square * homeData?.repaired)
												)}
												className="ml-1"
											/>
										</div>
									)}
							</ButtonBase>
						</div>
						<div className="flex justify-center mt-4">
							<ToggleButtonGroup
								color="primary"
								value={selectedCurrency}
								exclusive
								onChange={(event) => handleCurrencyChange(event.target.value)}
								aria-label="currency"
							>
								<ToggleButton color="primary" value="0">
									UZS
								</ToggleButton>
								<ToggleButton color="primary" value="1">
									USD
								</ToggleButton>
							</ToggleButtonGroup>
						</div>
					</div>

					<div className="home-payment-wrapper mt-3">
						<div className="home-payment-details">
							<div className="flex flex-row justify-between">
								<div className="w-1/2 mx-20">
									<NumericFormat
										id="price-currency-field"
										name="price"
										label={
											<span>
												1 m<sup>2</sup> narxi
											</span>
										}
										value={formik.values.price}
										onChange={(event) => {
											let formattedValue =
												event.target.value &&
												parseFloat(event.target.value.split(" ").join(""))
											formik.setFieldValue("price", formattedValue, true)
											if (repairData?.square) {
												formik.setFieldValue(
													"sum",
													formattedValue * repairData?.square
												)
												formik.setFieldValue(
													"left",
													formattedValue * repairData?.square -
														formik.values.startPrice -
														formik.values.discount
												)
											}
										}}
										error={formik.touched.price && Boolean(formik.errors.price)}
										helperText={formik.touched.price && formik.errors.price}
										component={motion.div}
										variants={fadeUp(30, "tween", 0.1, 0.5)}
										initial="hidden"
										animate="show"
										viewport={{ once: true, amount: 0.25 }}
										color="formColor"
										variant="outlined"
										fullWidth
										customInput={TextField}
										allowNegative={false}
										thousandSeparator={" "}
										decimalScale={1}
										inputProps={{
											readOnly: calculateByPrice
										}}
									/>
									{selectedCurrency === "1" && !isNaN(formik.values.price) && (
										<CurrencySubContent value={formik.values.price} />
									)}

									<FormControl fullWidth color="formColor" type="checkbox">
										<FormControlLabel
											control={
												<Checkbox
													id="calculate-by-price-or-sum-checkbox"
													name="calculate-by-price-or-sum-checkbox"
													checked={calculateByPrice}
													onChange={(event) => {
														setCalculateByPrice(event.target.checked)
													}}
												/>
											}
											label="Jami summa orqali hisoblash"
										/>
									</FormControl>

									<NumericFormat
										id="sum-currency-field"
										name="sum"
										label="Shartnoma summasi"
										value={formik.values.sum}
										onChange={(event) => {
											let formattedValue =
												event.target.value &&
												parseFloat(event.target.value.split(" ").join(""))
											formik.setFieldValue("sum", formattedValue, true)
										}}
										onBlur={() => {
											if (
												repairData?.square &&
												calculateByPrice &&
												formik.values.sum !==
													formik.values.price * repairData?.square
											) {
												let newPrice = Math.ceil(
													formik.values.sum / repairData?.square
												)
												formik.setFieldValue(
													"price",
													newPrice - (newPrice % 1000) + 1000,
													true
												)
												formik.setFieldValue(
													"discount",
													(newPrice - (newPrice % 1000) + 1000) *
														repairData?.square -
														formik.values.sum,
													true
												)
												formik.setFieldValue(
													"left",
													formik.values.sum - formik.values.startPrice,
													true
												)
												formik.setFieldValue(
													"sum",
													(newPrice - (newPrice % 1000) + 1000) *
														repairData?.square,
													true
												)
											}
										}}
										error={formik.touched.sum && Boolean(formik.errors.sum)}
										helperText={formik.touched.sum && formik.errors.sum}
										component={motion.div}
										variants={fadeUp(30, "tween", 0.1, 0.5)}
										initial="hidden"
										animate="show"
										viewport={{ once: true, amount: 0.25 }}
										color="formColor"
										variant="outlined"
										fullWidth
										customInput={TextField}
										allowNegative={false}
										thousandSeparator={" "}
										decimalScale={1}
										inputProps={{
											readOnly: !calculateByPrice
										}}
									/>
									{selectedCurrency === "1" && !isNaN(formik.values.sum) && (
										<CurrencySubContent value={formik.values.sum} />
									)}

									<NumericFormat
										id="start-price-currency-field"
										name="start-price"
										label="Boshlang'ich to'lov miqdori"
										value={formik.values.startPrice}
										onChange={(event) => {
											let formattedValue =
												event.target.value &&
												parseFloat(event.target.value.split(" ").join(""))
											formik.setFieldValue("startPrice", formattedValue, true)
											formik.setFieldValue(
												"left",
												formik.values.sum -
													formattedValue -
													formik.values.discount,
												true
											)
										}}
										error={
											formik.touched.startPrice &&
											Boolean(formik.errors.startPrice)
										}
										helperText={
											formik.touched.startPrice && formik.errors.startPrice
										}
										component={motion.div}
										variants={fadeUp(30, "tween", 0.2, 0.5)}
										initial="hidden"
										animate="show"
										viewport={{ once: true, amount: 0.25 }}
										color="formColor"
										variant="outlined"
										fullWidth
										customInput={TextField}
										allowNegative={false}
										thousandSeparator={" "}
										decimalScale={1}
									/>
									{selectedCurrency === "1" &&
										!isNaN(formik.values.startPrice) && (
											<CurrencySubContent value={formik.values.startPrice} />
										)}

									<NumericFormat
										id="left-currency-field"
										name="left"
										label="Qoldiq summa"
										value={formik.values.left}
										onChange={(event) => {
											let formattedValue =
												event.target.value &&
												parseFloat(event.target.value.split(" ").join(""))
											formik.setFieldValue("left", formattedValue, true)
										}}
										error={formik.touched.left && Boolean(formik.errors.left)}
										helperText={formik.touched.left && formik.errors.left}
										component={motion.div}
										variants={fadeUp(30, "tween", 0.3, 0.5)}
										initial="hidden"
										animate="show"
										viewport={{ once: true, amount: 0.25 }}
										color="formColor"
										variant="outlined"
										fullWidth
										customInput={TextField}
										allowNegative={false}
										thousandSeparator={" "}
										decimalScale={1}
										inputProps={{
											readOnly: true
										}}
									/>
									{selectedCurrency === "1" && !isNaN(formik.values.left) && (
										<CurrencySubContent value={formik.values.left} />
									)}

									<NumericFormat
										id="month"
										name="month"
										label="Kredit qaytarish muddati(oy)"
										value={formik.values.month}
										onChange={(event) => {
											formik.setFieldValue(
												"month",
												event.target.value
													? parseFloat(event.target.value)
													: "",
												true
											)
										}}
										error={formik.touched.month && Boolean(formik.errors.month)}
										helperText={formik.touched.month && formik.errors.month}
										component={motion.div}
										variants={fadeUp(30, "tween", 0.4, 0.5)}
										initial="hidden"
										animate="show"
										viewport={{ once: true, amount: 0.25 }}
										color="formColor"
										variant="outlined"
										fullWidth
										customInput={TextField}
										allowNegative={false}
										decimalScale={0}
									/>

									<NumericFormat
										id="interval"
										name="interval"
										label="Vaqt oralig'i"
										value={formik.values.interval}
										onChange={(event) => {
											formik.setFieldValue(
												"interval",
												event.target.value
													? parseFloat(event.target.value)
													: "",
												true
											)
										}}
										error={
											formik.touched.interval && Boolean(formik.errors.interval)
										}
										helperText={
											formik.touched.interval && formik.errors.interval
										}
										component={motion.div}
										variants={fadeUp(30, "tween", 0.5, 0.5)}
										initial="hidden"
										animate="show"
										viewport={{ once: true, amount: 0.25 }}
										color="formColor"
										variant="outlined"
										fullWidth
										customInput={TextField}
										allowNegative={false}
										decimalScale={0}
									/>
									<NumericFormat
										id="valute"
										name="valute"
										label="Dollar kursi"
										value={formik.values.valute}
										onChange={(event) => {
											formik.setFieldValue(
												"valute",
												event.target.value
													? parseFloat(event.target.value)
													: 0,
												true
											)
										}}
										error={
											formik.touched.valute && Boolean(formik.errors.valute)
										}
										helperText={
											formik.touched.valute && formik.errors.valute
										}
										component={motion.div}
										variants={fadeUp(30, "tween", 0.5, 0.5)}
										initial="hidden"
										animate="show"
										viewport={{ once: true, amount: 0.25 }}
										color="formColor"
										variant="outlined"
										fullWidth
										customInput={TextField}
										allowNegative={false}
										decimalScale={0}
									/>

								</div>

								<div className="w-1/2 mx-20">
									<LocalizationProvider dateAdapter={AdapterMoment}>
										<DatePicker
											id="start-date-picker"
											openTo="day"
											value={formik.values.startDate}
											onChange={(newValue) => {
												if (
													newValue &&
													newValue._isValid &&
													newValue > moment("2000-1-1")
												) {
													formik.setFieldValue(
														"startDate",
														moment(newValue).format("YYYY-MM-DD"),
														true
													)
												} else {
													formik.setFieldValue("startDate", "", true)
												}
											}}
											views={["year", "month", "day"]}
											inputFormat="DD/MM/yyyy"
											renderInput={(params) => (
												<TextField
													{...params}
													component={motion.div}
													variants={fadeUp(30, "tween", 0.5, 0.5)}
													initial="hidden"
													animate="show"
													viewport={{ once: true, amount: 0.25 }}
													color="formColor"
													variant="outlined"
													fullWidth
													id="start"
													name="start"
													label="Boshlang'ich to'lov sanasi"
													error={
														formik.touched.startDate &&
														Boolean(formik.errors.startDate)
													}
													helperText={
														formik.touched.startDate && formik.errors.startDate
													}
													autoComplete="off"
												/>
											)}
										/>
									</LocalizationProvider>

									<LocalizationProvider dateAdapter={AdapterMoment}>
										<DatePicker
											id="date-date-picker"
											openTo="day"
											value={formik.values.date}
											onChange={(newValue) => {
												if (
													newValue &&
													newValue._isValid &&
													newValue > moment("2000-1-1")
												) {
													formik.setFieldValue(
														"date",
														moment(newValue).format("YYYY-MM-DD"),
														true
													)
												} else {
													formik.setFieldValue("date", "", true)
												}
											}}
											views={["year", "month", "day"]}
											inputFormat="DD/MM/yyyy"
											renderInput={(params) => (
												<TextField
													{...params}
													component={motion.div}
													variants={fadeUp(30, "tween", 0.5, 0.5)}
													initial="hidden"
													animate="show"
													viewport={{ once: true, amount: 0.25 }}
													color="formColor"
													variant="outlined"
													fullWidth
													id="date"
													name="date"
													label="Birinchi to'lov sanasi"
													error={
														formik.touched.date && Boolean(formik.errors.date)
													}
													helperText={formik.touched.date && formik.errors.date}
													autoComplete="off"
												/>
											)}
										/>
									</LocalizationProvider>

									<NumericFormat
										id="discount-currency-field"
										name="discount"
										label="Chegirma miqdori"
										value={formik.values.discount}
										onChange={(event) => {
											let formattedValue =
												event.target.value &&
												parseFloat(event.target.value.split(" ").join(""))
											formik.setFieldValue("discount", formattedValue, true)
											formik.setFieldValue(
												"left",
												formik.values.sum -
													formik.values.startPrice -
													formattedValue,
												true
											)
										}}
										error={
											formik.touched.discount && Boolean(formik.errors.discount)
										}
										helperText={
											formik.touched.discount && formik.errors.discount
										}
										component={motion.div}
										variants={fadeUp(30, "tween", 0.3, 0.5)}
										initial="hidden"
										animate="show"
										viewport={{ once: true, amount: 0.25 }}
										color="formColor"
										variant="outlined"
										fullWidth
										customInput={TextField}
										allowNegative={false}
										thousandSeparator={" "}
										decimalScale={1}
									/>
									{selectedCurrency === "1" &&
										!isNaN(formik.values.discount) && (
											<CurrencySubContent value={formik.values.discount} />
										)}
									<TextField
										component={motion.div}
										variants={fadeUp(30, "tween", 0.4, 0.5)}
										initial="hidden"
										animate="show"
										viewport={{ once: true, amount: 0.25 }}
										color="formColor"
										variant="outlined"
										fullWidth
										id="contractName"
										name="contractName"
										label="Shartnoma raqami"
										error={
											formik.touched.contractName &&
											Boolean(formik.errors.contractName)
										}
										helperText={
											formik.touched.contractName && formik.errors.contractName
										}
										value={formik.values.contractName}
										onChange={(event) => {
											formik.setFieldValue(
												"contractName",
												event.target.value,
												true
											)
										}}
										autoComplete="off"
									/>

									<TextField
										component={motion.div}
										variants={fadeUp(30, "tween", 0.4, 0.5)}
										initial="hidden"
										animate="show"
										viewport={{ once: true, amount: 0.25 }}
										color="formColor"
										variant="outlined"
										fullWidth
										id="comment"
										name="comment"
										label="Komentariya"
										multiline
										rows={6}
										error={
											formik.touched.comment && Boolean(formik.errors.comment)
										}
										helperText={formik.touched.comment && formik.errors.comment}
										value={formik.values.comment}
										onChange={(event) => {
											formik.setFieldValue("comment", event.target.value, true)
										}}
										autoComplete="off"
									/>
									<SimpleSelectField
										delay={0}
										label="Shartnoma turi"
										name="contractName"
										value={contractType}
										changeFn={handleContractType}
										options={contractTypes}
									/>
								</div>
							</div>
						</div>
					</div>

					{!isNaN(formik.values.month) &&
						!isNaN(formik.values.left) &&
						formik.values.date &&
						!isNaN(formik.values.interval) && (
							<Fragment>
								<div className="step-three-payment-tables-wrapper">
									<Tabs
										value={tabValue}
										onChange={(event, newValue) => setTabValue(newValue)}
										className="contract-step-three-table-tabs"
									>
										<Tab
											label="To'lov jadvalini avtomatik shakllantirish"
											value="automatic"
										/>
										<Tab
											label="To'lov jadvalini qo'lda shakllantirish"
											value="manually"
										/>
									</Tabs>
								</div>

								{tabValue === "automatic" && (
									<div>
										<div className="flex justify-center mt-4">
											<ToggleButtonGroup
												color="primary"
												value={selectedCurrency2}
												exclusive
												onChange={(event) => handleCurrencyChange2(event.target.value)}
												aria-label="currency2"
											>
												<ToggleButton color="primary" value="0">
													UZS
												</ToggleButton>
												<ToggleButton color="primary" value="1">
													USD
												</ToggleButton>
											</ToggleButtonGroup>
										</div>
										<StepThreeTabOne
										month={formik.values.month}
										left={formik.values.left}
										date={formik.values.date}
										interval={formik.values.interval}
										paymentDataList={paymentDataList}
										setPaymentDataList={setPaymentDataList}
										currency={selectedCurrency}
										currency2={selectedCurrency2}
									/>
									</div>
								)}

								{tabValue === "manually" && (
									<StepThreeTabTwo
										month={formik.values.month}
										left={formik.values.left}
										date={formik.values.date}
										interval={formik.values.interval}
										paymentDataList={paymentDataList2}
										setPaymentDataList={setPaymentDataList2}
										currency={selectedCurrency}
									/>
								)}
							</Fragment>
						)}

					<div className="text-center my-4">
						<Button
							onClick={back}
							color="inherit"
							variant="contained"
							className="!mr-2"
						>
							Ortga
						</Button>
						<Button
							color="success"
							variant="contained"
							className="!ml-2"
							onClick={handleNext}
						>
							Oldinga
						</Button>
					</div>
				</div>
			</div>
		</motion.div>
	)
}

export default StepThree
