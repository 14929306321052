import { Button, Chip, CircularProgress, Tab, Tabs } from "@mui/material"
import BackButton from "components/ui/BackButton"
import useAxiosPrivate from "hooks/useAxiosPrivate"
import useTopPanel from "hooks/useTopPanel"
import moment from "moment"
import React, { Fragment, useEffect, useState } from "react"
import { useQueries } from "react-query"
import { useParams } from "react-router-dom"
import CLIENT_TYPE from "shared/clientTypeList"
import PhoneFormat from "components/ui/text-formats/PhoneFormat"
import BaseTooltipCustomWidth from "components/ui/tooltips/BaseTooltipCustomWidth"
import ClientContract from "./ClientContract"
import ClientLoginAddEditModal from "./ClientLoginAddEditModal"
import { clientTypeVariants } from "shared/tableColVariantsList"

const ClientView = () => {
	const { id } = useParams()
	const axiosPrivate = useAxiosPrivate()
	const [hasError, setHasError] = useState(false)
	const [openClientLoginModal, setOpenClientLoginModal] = useState(false)
	const [tabValue, setTabValue] = useState(0)
	const { setComponent } = useTopPanel()

	const [clientQuery, contractsQuery] = useQueries([
		{
			queryKey: "customerSingle",
			queryFn: async function () {
				const response = await axiosPrivate.get(`/admin/custom/edit/${id}`)
				return response.data.data
			},
			enabled: !hasError && !!id,
			onError: (error) => {
				setHasError(true)
			},
			retry: false
		},
		{
			queryKey: "clientContracts",
			queryFn: async function () {
				const response = await axiosPrivate.get(
					`/dictionary/customcontracts/${id}`
				)
				return response.data.data
			},
			/* onSuccess: (data) => {
				if (data && data.length > 0) {
					setTabValue(data[0]?.id)
				}
			}, */
			enabled: !hasError && !!id,
			onError: (error) => {
				setHasError(true)
			},
			retry: false
		}
	])

	const setClientType = (item) => {
		let result = clientTypeVariants.filter((variant) => variant.code === item)
		if (result.length > 0) {
			return (
				<Chip
					label={result[0]?.label}
					variant="tableBadge"
					color={result[0].color}
				/>
			)
		}
		return ""
	}

	const handleAddClientLoginPassword = () => {
		setOpenClientLoginModal(true)
	}

	const handleEditClientLoginPassword = () => {
		setOpenClientLoginModal(true)
	}

	useEffect(() => {
		setComponent(
			<div className="flex flex-row items-center">
				<BackButton />
				<div className="text-base-color text-xl font-medium flex flex-row">
					<BaseTooltipCustomWidth
						arrow={true}
						placement="bottom"
						enterDelay={1000}
						leaveTouchDelay={0}
						title={`${clientQuery?.data?.surname} ${clientQuery?.data?.name} ${clientQuery?.data?.middlename} ma'lumotlari`}
						width={"800px"}
						fontSize={"1rem"}
					>
						<span className="text-line-1 max-w-[800px] ml-1">
							{clientQuery?.data?.surname} {clientQuery?.data?.name}{" "}
							{clientQuery?.data?.middlename} ma'lumotlari
						</span>
					</BaseTooltipCustomWidth>
				</div>
			</div>
		)
	}, [clientQuery.data])

	return (
		<div className="component-add-edit-wrapper mx-4">
			{clientQuery.isLoading || clientQuery.isFetching ? (
				<div className="circular-progress-box py-5">
					<CircularProgress size={35} />
				</div>
			) : (
				<div className="client-view-wrapper">
					{clientQuery &&
					clientQuery?.data &&
					clientQuery.data.client_type &&
					clientQuery.data.client_type === CLIENT_TYPE.PHYSICAL.code ? (
						<Fragment>
							<div className="client-data-wrapper md:w-1/2 w-full pr-1">
								<div className="client-data-item">
									<div className="data-item-title">Ismi:</div>
									<div className="data-item-value">
										{clientQuery.data?.name}
									</div>
								</div>

								<div className="client-data-item">
									<div className="data-item-title">Familiyasi:</div>
									<div className="data-item-value">
										{clientQuery.data?.surname}
									</div>
								</div>

								<div className="client-data-item">
									<div className="data-item-title">Sharifi:</div>
									<div className="data-item-value">
										{clientQuery.data?.middlename}
									</div>
								</div>

								<div className="client-data-item">
									<div className="data-item-title">
										Passport seriya va raqami:
									</div>
									<div className="data-item-value">
										{clientQuery.data?.detail?.passport_series}
									</div>
								</div>

								<div className="client-data-item">
									<div className="data-item-title">Passport berilgan sana:</div>
									<div className="data-item-value">
										{clientQuery.data?.detail?.issue &&
											moment(clientQuery.data?.detail?.issue).format(
												"DD/MM/YYYY"
											)}
									</div>
								</div>

								<div className="client-data-item">
									<div className="data-item-title">Passport berilgan joy:</div>
									<div className="data-item-value">
										{clientQuery.data?.detail?.authority}
									</div>
								</div>

								<div className="client-data-item">
									<div className="data-item-title">Ish joyi:</div>
									<div className="data-item-value">
										{clientQuery.data?.detail?.work_place}
									</div>
								</div>

								<div className="mt-4">
									{clientQuery.data.connect ? (
										<Button
											color="warning"
											variant="contained"
											onClick={() => handleEditClientLoginPassword()}
										>
											<span>Mijoz login va parolini o'zgartirish</span>
										</Button>
									) : (
										<Button
											color="success"
											variant="contained"
											onClick={() => handleAddClientLoginPassword()}
										>
											<span>Mijozga login va parol yaratish</span>
										</Button>
									)}
								</div>
							</div>

							<div className="client-data-wrapper md:w-1/2 w-full pl-1">
								<div className="client-data-item">
									<div className="data-item-title">Tu'gilgan kuni:</div>
									<div className="data-item-value">
										{clientQuery.data?.detail?.birthday &&
											moment(clientQuery.data?.detail?.birthday).format(
												"DD/MM/YYYY"
											)}
									</div>
								</div>

								<div className="client-data-item">
									<div className="data-item-title">Telefon raqami:</div>
									<div className="data-item-value flex flex-col">
										<PhoneFormat value={clientQuery.data?.phone} />
										<PhoneFormat value={clientQuery.data?.phone2} />
									</div>
								</div>

								<div className="client-data-item">
									<div className="data-item-title">Mijoz turi:</div>
									<div className="data-item-value">
										{clientQuery.data?.client_type &&
											setClientType(clientQuery.data?.client_type)}
									</div>
								</div>

								<div className="client-data-item">
									<div className="data-item-title">Viloyat:</div>
									<div className="data-item-value">
										{clientQuery.data?.detail?.regions?.name}
									</div>
								</div>

								<div className="client-data-item">
									<div className="data-item-title">Shahar/Tuman:</div>
									<div className="data-item-value">
										{clientQuery.data?.detail?.city}
									</div>
								</div>

								<div className="client-data-item">
									<div className="data-item-title">Manzili:</div>
									<div className="data-item-value">
										{clientQuery.data?.detail?.home}
									</div>
								</div>
							</div>
						</Fragment>
					) : (
						<Fragment>
							<div className="client-data-wrapper md:w-1/2 w-full">
								<div className="client-data-item">
									<div className="data-item-title">Tashkilot nomi:</div>
									<div className="data-item-value">
										{clientQuery.data?.name}
									</div>
								</div>

								<div className="client-data-item">
									<div className="data-item-title">Tashkilot STIR(ИНН):</div>
									<div className="data-item-value">{clientQuery.data?.inn}</div>
								</div>

								<div className="client-data-item">
									<div className="data-item-title">Tashkilot MFO si:</div>
									<div className="data-item-value">{clientQuery.data?.mfo}</div>
								</div>

								<div className="client-data-item">
									<div className="data-item-title">Tashkilot OKED i:</div>
									<div className="data-item-value">
										{clientQuery.data?.oked}
									</div>
								</div>

								<div className="client-data-item">
									<div className="data-item-title">Tashkilot hisob raqami:</div>
									<div className="data-item-value">
										{clientQuery.data?.account_number}
									</div>
								</div>

								<div className="client-data-item">
									<div className="data-item-title">Bank nomi:</div>
									<div className="data-item-value">
										{clientQuery.data?.bank_name}
									</div>
								</div>

								<div className="mt-4">
									{clientQuery.data.connect ? (
										<Button
											color="warning"
											variant="contained"
											onClick={() => handleEditClientLoginPassword()}
										>
											<span>Mijoz login va parolini o'zgartirish</span>
										</Button>
									) : (
										<Button
											color="success"
											variant="contained"
											onClick={() => handleAddClientLoginPassword()}
										>
											<span>Mijozga login va parol yaratish</span>
										</Button>
									)}
								</div>
							</div>

							<div className="client-data-wrapper md:w-1/2 w-full">
								<div className="client-data-item">
									<div className="data-item-title">Telefon raqami:</div>
									<div className="data-item-value flex flex-col">
										<PhoneFormat value={clientQuery.data?.phone} />
										<PhoneFormat value={clientQuery.data?.phone2} />
									</div>
								</div>

								<div className="client-data-item">
									<div className="data-item-title">Mijoz turi:</div>
									<div className="data-item-value">
										{clientQuery.data?.client_type &&
											setClientType(clientQuery.data?.client_type)}
									</div>
								</div>

								<div className="client-data-item">
									<div className="data-item-title">Viloyat:</div>
									<div className="data-item-value">
										{clientQuery.data?.detail?.regions?.name}
									</div>
								</div>

								<div className="client-data-item">
									<div className="data-item-title">Shahar/Tuman:</div>
									<div className="data-item-value">
										{clientQuery.data?.detail?.city}
									</div>
								</div>

								<div className="client-data-item">
									<div className="data-item-title">Tashkilot manzili:</div>
									<div className="data-item-value">
										{clientQuery.data?.detail?.home}
									</div>
								</div>
							</div>
						</Fragment>
					)}
				</div>
			)}

			<div className="client-contracts-wrapper mt-6 pb-6">
				<div className="client-contracts-title text-xl font-medium text-center mb-4">
					Mijoz bilan tuzilgan shartnomalar
				</div>
				{contractsQuery.isLoading || contractsQuery.isFetching ? (
					<div className="circular-progress-box py-5">
						<CircularProgress size={35} />
					</div>
				) : contractsQuery &&
				  contractsQuery.data &&
				  contractsQuery.data.length > 0 ? (
					<Fragment>
						<Tabs
							value={tabValue}
							onChange={(event, newValue) => setTabValue(newValue)}
							className="client-contracts-tabs"
						>
							{contractsQuery.data.map((contract, index) => (
								<Tab
									label={"Shartnoma: " + contract?.name}
									value={index}
									key={`contract-tab-${contract?.id}`}
								/>
							))}
						</Tabs>
						<ClientContract
							contractData={contractsQuery.data[tabValue]}
							refetchFn={contractsQuery.refetch}
						/>
					</Fragment>
				) : (
					<div className="mt-6 p-4 rounded-lg my-shadow-2">
						<span className="no-data-found-wrapper">
							<i className="bi bi-exclamation-octagon text-xl mr-1 leading-3" />{" "}
							Shartnomalar topilmadi!
						</span>
					</div>
				)}
			</div>

			{openClientLoginModal && (
				<ClientLoginAddEditModal
					open={openClientLoginModal}
					setOpen={setOpenClientLoginModal}
					clientData={clientQuery.data}
					refetch={clientQuery.refetch}
				/>
			)}
		</div>
	)
}

export default ClientView
