/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from '@mui/material';
import FormActionButtons from 'components/ui/form/FormActionButtons';
import FormCurrencyField from 'components/ui/form/FormCurrencyField';
import FormNumberTextField from 'components/ui/form/FormNumberTextField';
import { useFormik } from 'formik';
import useFormSubmit from 'hooks/useFormSubmit';
import * as yup from 'yup';
import useCurrency from 'hooks/useCurrency';
import { useState } from 'react';
import useTopPanel from 'hooks/useTopPanel';
import useNotification from 'hooks/useNotification';
import './style.css';
import { Link } from 'react-router-dom';
function setValidation(field, vvv) {
  return field
    ? vvv.required("Valyutaning so'mdagi miqdorini kiritish majburiy!")
    : null;
}

const AssignPrize = (props) => {
  const { open, setOpen, setRefetch } = props;
  const { submit, isSubmitting } = useFormSubmit();
  const sendNotification = useNotification();
  const { currencyData, updateCurrencyData } = useCurrency();
  const [state, setState] = useState({
    repaired: null,
    norepaired: 1,
  });
  const validationSchema = yup.object({
    repairedSum: setValidation(this?.repaired, yup.number()),
    norepairedSum: setValidation(this?.norepaired, yup.number()),
    from: yup.number().required(),
    till: yup.number().required(),
  });
  const formik = useFormik({
    initialValues: {
      repairedSum: '',
      norepairedSum: '',
      from: '',
      till: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      submit(
        { type: 'get', contentType: 'getPrize' },
        {},
        `${process.env.REACT_APP_BACKEND_URL}/price/8?from=${from}&till=${till}${noRep}${noRepSum}${rep}${repSum}`,
        {
          title: 'Muvaffaqiyatli yaratildi!',
        },
        null,
        true,
        handleFinish
      );
    },
  });
  const { repairedSum, norepairedSum, from, till } = formik.values;
  const noRep = state?.norepaired ? '&norepaired=' + state?.norepaired : '';
  const rep = state?.repaired ? '&repaired=' + state?.repaired : '';
  const noRepSum =
    norepairedSum && state?.norepaired ? '&norepairedSum=' + norepairedSum : '';
  const repSum =
    repairedSum && state?.repaired ? '&repairedSum=' + repairedSum : '';

  const handleFinish = () => {
    updateCurrencyData();
    setRefetch(true);
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
    formik.resetForm();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      maxWidth='lg'
      disableEscapeKeyDown={true}
    >
      <DialogTitle id='alert-dialog-title'>
        <span className='pr-5'>Narx olish</span>
        <div className='close-btn-wrapper'>
          <IconButton variant='onlyIcon' color='primary' onClick={handleClose}>
            <i className='bi bi-x' />
          </IconButton>
        </div>
      </DialogTitle>
      <div id='add_home_modal'>
        {' '}
        <DialogContent>
          <Grid
            container
            spacing={{ xs: 2, sm: 2 }}
            rowSpacing={1}
            columns={{ xs: 12, sm: 12 }}
            pb={2}
          >
            {[
              {
                en: 'norepaired',
                uz: 'TAMIRSIZ',
                colored: state?.norepaired ? 'colored' : '',
              },
              {
                en: 'repaired',
                uz: 'TAMIRLI',
                colored: state?.repaired ? 'colored' : '',
              },
            ].map((item) => (
              <Grid
                className='no_repaired_box'
                item={true}
                sm={6}
                xs={6}
                key={item.en}
                pr={0}
              >
                <div
                  onClick={(e) => {
                    e.preventDefault();
                    setState({
                      ...state,
                      [item.en]: state?.[item.en] ? null : 1,
                    });
                    formik.setFieldValue([item?.en + 'Sum'], '', true);
                  }}
                  className={'no_repaired ' + item.colored}
                >
                  <>{item.uz}</>
                </div>
              </Grid>
            ))}
          </Grid>
          <form onSubmit={formik.handleSubmit} className='pt-2'>
            <Grid
              container
              spacing={{ xs: 2, sm: 2 }}
              rowSpacing={1}
              columns={{ xs: 12, sm: 12 }}
            >
              <Grid item={true} sm={12} xs={12}>
                <FormCurrencyField
                  delay={0.1}
                  label='Tamirsiz narxi'
                  fieldName='norepairedSum'
                  formik={formik}
                  disabled={!state?.norepaired}
                />
              </Grid>
              <Grid item={true} sm={12} xs={12}>
                <FormCurrencyField
                  delay={0.1}
                  label='Tamirli narxi'
                  fieldName='repairedSum'
                  formik={formik}
                  disabled={!state?.repaired}
                />
              </Grid>
              <Grid item={true} sm={6} xs={6}>
                <FormNumberTextField
                  delay={0.1}
                  label='qavatdan'
                  fieldName='from'
                  formik={formik}
                />
              </Grid>
              <Grid item={true} sm={6} xs={6}>
                <FormNumberTextField
                  delay={0.1}
                  label='qavatgach'
                  fieldName='till'
                  formik={formik}
                />
              </Grid>

              <Grid item={true} sm={12} xs={12}>
                <Link
                  to={
                    from && till
                      ? `${process.env.REACT_APP_BACKEND_URL}/price/8?from=${from}&till=${till}${noRep}${noRepSum}${rep}${repSum}`
                      : window.location.href
                  }
                  className='no-underline'
                  onClick={() => {
                    !from &&
                      !till &&
                      sendNotification({
                        msg: 'Iltimos qiymatlarni kiriting!',
                        variant: 'warning',
                      });
                    return;
                  }}
                >
                  <div className='row action_wrapper'>
                    <Button variant='contained' autoFocus>
                      save
                    </Button>
                  </div>
                </Link>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </div>
    </Dialog>
  );
};

export default AssignPrize;

// /* eslint-disable react-hooks/exhaustive-deps */
// import * as React from 'react';
// import Button from '@mui/material/Button';
// import Dialog from '@mui/material/Dialog';
// import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
// import useNotification from '../../../hooks/useNotification';
// import './style.css';
// import useAuth from '../../../hooks/useAuth';
// import { thousandsSeparator } from '../../../utils/formatters';

// export default function AddHome({ open, setOpen }) {
//   const [{ user }] = useAuth();
//   const sendNotification = useNotification();
//   const [state, setState] = React.useState({
//     norepaired: 1,
//     norepairedSum: 1312313131,
//     repaired: null,
//     repairedSum: null,
//     from: null,
//     till: null,
//   });
//   const { from, till, repaired, repairedSum, norepaired, norepairedSum } =
//     state;
//   const handleClose = () => {
//     setOpen(false);
//   };

//   const handleSubmit = () => {
//     if (!state.from && !state.till) {
//       sendNotification({
//         msg: 'Iltimos patezdan patezgacha qiymatini kiriting!',
//         variant: 'warning',
//       });
//     } else {
//       const noRep = norepaired ? '&norepaired=' + norepaired : '';
//       const rep = repaired ? '&repaired=' + repaired : '';
//       const noRepSum = norepairedSum ? '&norepairedSum=' + norepairedSum : '';
//       const repSum = repairedSum ? '&repairedSum=' + repairedSum : '';
//       fetch(
//         `${process.env.REACT_APP_BACKEND_URL}/price/8?from=${from}&till=${till}${noRep}${noRepSum}${rep}${repSum}`,
//         {
//           method: 'GET',
//           // body: JSON.stringify({
//           //   title: 'foo',
//           //   body: 'bar',
//           //   userId: 1,
//           // }),
//           headers: {
//             Authorization: `Bearer ${user?.access_token}`,
//             'Content-type': 'application/json; charset=UTF-8',
//           },
//         }
//       )
//         .then((response) => response.json())
//         .then(() => {
//           sendNotification({
//             msg: 'Iltimos patezlar oraliq qiymatini kiriting!',
//             variant: 'success',
//           });
//           handleClose();
//         });
//     }
//   };

//   const handleChange = (e) => {
//     const { value, name } = e.target;
//     setState({
//       ...state,
//       [name]: +value || null,
//     });
//   };
//   console.log(state);
//   return (
//     <>
//       <Dialog
//         open={open}
//         onClose={handleSubmit}
//         aria-labelledby='alert-dialog-title'
//         aria-describedby='alert-dialog-description'
//       >
//         <div id='add_home_modal'>
//           <div className='row title'>
//             <div>Narx Olish</div>
//             <div onClick={handleClose}>X</div>
//           </div>
//           <DialogContent>
//             <DialogContentText id='alert-dialog-description'>
//               <div className='row'>
//                 {[
//                   {
//                     en: 'norepaired',
//                     uz: 'TAMIRSIZ',
//                     colored: norepaired ? 'colored' : '',
//                   },
//                   {
//                     en: 'repaired',
//                     uz: 'TAMIRLI',
//                     colored: repaired ? 'colored' : '',
//                   },
//                 ].map((item) => (
//                   <div
//                     key={item.en}
//                     onClick={() => {
//                       setState({
//                         ...state,
//                         [item.en]: state?.[item.en] ? null : 1,
//                         [item.en + 'Sum']: null,
//                       });
//                       clearInputById(item.en + 'Sum');
//                     }}
//                     className={'no_repaired ' + item.colored}
//                   >
//                     {item.uz}
//                   </div>
//                 ))}
//               </div>
//               <div className='row'>
//                 <div className='row-item'>
//                   <label htmlFor='norepairedSum'>tamirsiz</label>
//                   <input
//                     onChange={handleChange}
//                     value={+thousandsSeparator(norepairedSum)}
//                     type='number'
//                     name='norepairedSum'
//                     id='norepairedSum'
//                     className='price_input'
//                     placeholder='0.00'
//                     disabled={!norepaired}
//                   />
//                 </div>
//               </div>
//               <div className='row'>
//                 <div className='row-item'>
//                   <label htmlFor='repairedSum'>tamirli</label>
//                   <input
//                     onChange={handleChange}
//                     value={repairedSum}
//                     type='number'
//                     name='repairedSum'
//                     id='repairedSum'
//                     className='price_input'
//                     placeholder='0.00'
//                     disabled={!repaired}
//                   />
//                 </div>
//               </div>
//               <div className='row'>
//                 <div className='row-item'>
//                   <label htmlFor='from'>
//                     patezdan <span className='required'>*</span>
//                   </label>
//                   <input
//                     onChange={handleChange}
//                     value={from}
//                     type='number'
//                     name='from'
//                     id='from'
//                     className='price_input'
//                     placeholder='0.00'
//                   />
//                 </div>
//                 <div className='row-item'>
//                   <label htmlFor='till'>
//                     patezgacha<span className='required'>*</span>
//                   </label>
//                   <input
//                     onChange={handleChange}
//                     value={till}
//                     type='number'
//                     name='till'
//                     id='till'
//                     className='price_input'
//                     placeholder='0.00'
//                   />
//                 </div>
//               </div>
//               <div className='row action_wrapper'>
//                 <Button onClick={handleSubmit} variant='contained' autoFocus>
//                   save
//                 </Button>
//               </div>
//             </DialogContentText>
//           </DialogContent>
//         </div>
//       </Dialog>
//     </>
//   );
//   function clearInputById(id) {
//     const inputs = document.querySelector(`#${id}`);
//     inputs.value = '';
//   }
// }
