import React from "react"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import { CircularProgress } from "@mui/material"

const DeleteConfirmDialog = ({ open, setOpen, handleAction, loading }) => {
	return (
		<div>
			<Dialog
				open={open}
				onClose={() => setOpen(false)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				disableEscapeKeyDown={true}
			>
				<DialogTitle id="alert-dialog-title">
					Siz ushbu elementni o'chirishni xohlaysizmi?
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Agar siz ushbu elementni o'chirsangiz amalni qaytarishni imkoni bo'lmaydi!
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => handleAction("cancel")} color="inherit">
						Bekor qilish
					</Button>
					<Button
						onClick={() => handleAction("delete")}
						color="primary"
						disabled={loading}
					>
						{loading && (
							<CircularProgress size={15} color="inherit" className="mr-1" />
						)}
						O'chirish
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	)
}

export default DeleteConfirmDialog
