const RESIDENT_TYPE = {
	NON_RESIDENTIAL: {
		code: "0",
		label: "Noturar"
	},
	RESIDENTIAL: {
		code: "1",
		label: "Turar"
	}
}

module.exports = RESIDENT_TYPE
