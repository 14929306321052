import React from "react"
import { motion } from "framer-motion"
import { textContainer, textVariant2 } from "utils/motion"

export const SectionTitleText = ({ title, textStyles, duration = 0.15 }) => (
	<motion.p
		variants={textContainer(duration)}
		initial="hidden"
		whileInView="show"
		className={textStyles}
		viewport={{ once: true, amount: 0.25 }}
	>
		{Array.from(title).map((letter, index) => (
			<motion.span variants={textVariant2} key={index}>
				{letter === " " ? "\u00A0" : letter}
			</motion.span>
		))}
	</motion.p>
)
