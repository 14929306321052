const CONTRACT_STATUS_TYPE = {
	ACTIVE: {
		code: "1",
		label: "Faol"
	},
	COMPLETE: {
		code: "2",
		label: "Tugallangan"
	},
	CANCEL: {
		code: "3",
		label: "Bekor qilingan"
	}
}

module.exports = CONTRACT_STATUS_TYPE
