import { Fab } from "@mui/material"
import React, { Fragment } from "react"
import { NavLink, useLocation } from "react-router-dom"

const InfoActionButton = ({ link = "", isReplace = false, replace_url = "", replace_str = "" }) => {
	const location = useLocation()
	let locPath = location.pathname
	if(isReplace){
		locPath = locPath.replace(replace_url, replace_str)
	}
	return (
		<Fragment>
			<NavLink to={locPath + link} className="no-underline">
				<Fab color="info" variant="action" aria-label="info">
					<i className="bi bi-info-lg !text-lg" />
				</Fab>
			</NavLink>
		</Fragment>
	)
}

export default InfoActionButton
