const ROLE_TYPE_LIST = {
	ADMIN: { code: "1", label: "Admin" },
	ACCOUNTER: { code: "2", label: "Kassa" },
	BOSS: { code: "3", label: "Boss" },
	CUSTOM: { code: "4", label: "Mijoz" },
	MANAGER: { code: "5", label: "Menejer" },
	OPERATOR: { code: "6", label: "Operator" },
	CASHER: { code: "7", label: "Kassir" },
}

module.exports = ROLE_TYPE_LIST
