import { Button, Grid } from "@mui/material"
import PaymentHistoryTable from "components/ui/tables/PaymentHistoryTable"
import CurrencyFormat from "components/ui/text-formats/CurrencyFormat"
import { Fragment, useState } from "react"
import { Link } from "react-router-dom"
import REPAIR_TYPE from "shared/repairTypeList"
import getLabelByTypeList from "utils/getLabelByTypeList"
import SuccessTooltip from "components/ui/tooltips/SuccessTooltip"
import ContractPaymentListModal from "../contract/ContractPaymentListModal"
import ContractCancellationModal from "../contract/ContractCancellationModal"
import PaymentAddModal from "../../accounter/payment/PaymentAddModal"

import InfoTooltip from "components/ui/tooltips/InfoTooltip"
import ROLE_TYPE_LIST from "shared/roleTypeList"
import useAuth from "hooks/useAuth"
import ImagePreviewDialog from "components/ui/dialogs/ImagePreviewDialog"

const ClientContract = ({ contractData = {}, refetchFn = () => {} }) => {
	const [open, setOpen] = useState(false)
	const [{ user }] = useAuth()
	const [cancellationOpen, setCancellationOpen] = useState(false)
	const [openPaymentModal, setOpenPaymentModal] = useState(false)

	const [openHomeLayoutImageDialog, setOpenHomeLayoutImageDialog] =
		useState(false)

	return (
		<div className="component-add-edit-wrapper p-4 my-shadow-2 rounded-lg mt-4">
			{contractData && (
				<Fragment>
					<div className="component-add-edit-header mt-3">
						<Grid
							container
							spacing={{ xs: 2, sm: 3, lg: 3 }}
							rowSpacing={1}
							columns={{ xs: 12, sm: 12, lg: 15 }}
						>
							<Grid item={true} lg={3} sm={6} xs={12}>
								<Button
									color="secondary"
									variant="contained"
									fullWidth
									className="!h-full"
								>
									<div className="flex flex-col p-2">
										<span className="text-lg leading-5 mb-1">
											Shartnoma summasi
										</span>
										<span className="text-sm">
											<CurrencyFormat
												value={contractData?.sum}
												suffix={contractData?.isvalute === "1" ? " $" : " UZS"}
											/>
										</span>
									</div>
								</Button>
							</Grid>
							<Grid item={true} lg={3} sm={6} xs={12}>
								<Button
									color="info"
									variant="contained"
									fullWidth
									className="!h-full"
								>
									<div className="flex flex-col p-2">
										<span className="text-lg leading-5 mb-1">
											Boshlang'ich to'lov
										</span>
										<span className="text-sm">
											<CurrencyFormat
												value={contractData?.start_price}
												suffix={contractData?.isvalute === "1" ? " $" : " UZS"}
											/>
										</span>
									</div>
								</Button>
							</Grid>
							<Grid item={true} lg={3} sm={6} xs={12}>
								<Button
									color="warning"
									variant="contained"
									fullWidth
									className="!h-full"
								>
									<div className="flex flex-col p-2">
										<span className="text-lg leading-5 mb-1">
											1 m<sup>2</sup> uchun to'lov miqdori
										</span>
										<span className="text-sm">
											<CurrencyFormat
												value={contractData?.price}
												suffix={contractData?.isvalute === "1" ? " $" : " UZS"}
											/>
										</span>
									</div>
								</Button>
							</Grid>
							<Grid item={true} lg={3} sm={6} xs={12}>
								<Button
									color="success"
									variant="contained"
									fullWidth
									className="!h-full"
								>
									<div className="flex flex-col p-2">
										<span className="text-lg leading-5 mb-1">
											Chegirma miqdori
										</span>
										<span className="text-sm">
											<CurrencyFormat
												value={contractData?.discount}
												suffix={contractData?.isvalute === "1" ? " $" : " UZS"}
											/>
										</span>
									</div>
								</Button>
							</Grid>
							<Grid item={true} lg={3} sm={6} xs={12}>
								<Button
									color="error"
									variant="contained"
									fullWidth
									className="!h-full"
								>
									<div className="flex flex-col p-2">
										<span className="text-lg leading-5 mb-1">
											Qolgan to'lov miqdori
										</span>
										<span className="text-sm">
											<CurrencyFormat
												value={contractData?.leftsum}
												allowNegative={true}
												suffix={" UZS"}
											/>
										</span>
										<span className="text-sm">
											<CurrencyFormat
												value={contractData?.leftvalute}
												allowNegative={true}
												suffix={" $"}
											/>
										</span>
									</div>
								</Button>
							</Grid>
						</Grid>
					</div>
					{contractData?.comment && (
						<div className="contract-comment my-shadow-2 rounded-lg p-4 w-full mt-4 mb-1">
							<div>
								<span className="font-medium text-base-color">
									Kommentariya:
								</span>{" "}
								{contractData?.comment}
							</div>
						</div>
					)}
					<div className="component-add-edit-body">
						<div className="py-3 flex flex-row">
							<div className="base-data w-full">
								<div className="home-data flex items-center justify-between my-shadow-2 rounded-lg p-4 w-full mb-4">
									<div className="home-data-item flex flex-col">
										<span className="data-item-title text-base font-medium text-base-color">
											Obyekt nomi:
										</span>
										<span className="data-item-value text-base">
											{contractData?.homes?.blocks?.objects?.name}
										</span>
									</div>
									<div className="home-data-item flex flex-col">
										<span className="data-item-title text-base font-medium text-base-color">
											Blok nomi:
										</span>
										<span className="data-item-value text-base">
											{contractData?.homes?.blocks?.name}
										</span>
									</div>
									<div className="home-data-item flex flex-col">
										<span className="data-item-title text-base font-medium text-base-color">
											Xonadon raqami:
										</span>
										<span className="data-item-value text-base">
											{contractData?.homes?.number}
										</span>
									</div>
									<div className="home-data-item flex flex-col">
										<span className="data-item-title text-base font-medium text-base-color">
											Qavati:
										</span>
										<span className="data-item-value text-base">
											{contractData?.homes?.stage}
										</span>
									</div>
									<div className="home-data-item flex flex-col">
										<span className="data-item-title text-base font-medium text-base-color">
											Xonalar soni:
										</span>
										<span className="data-item-value text-base">
											{contractData?.homes?.rooms}
										</span>
									</div>
									<div className="home-data-item flex flex-col">
										<span className="data-item-title text-base font-medium text-base-color">
											Maydoni:
										</span>
										<span className="data-item-value text-base">
											{contractData?.homes?.square} m<sup>2</sup>
										</span>
									</div>
									<div className="home-data-item flex flex-col">
										<span className="data-item-title text-base font-medium text-base-color">
											Ta'mir holati:
										</span>
										<span className="data-item-value text-base">
											{getLabelByTypeList(
												REPAIR_TYPE,
												contractData?.isrepaired
											)}
										</span>
									</div>
									<div className="home-data-item flex flex-col">
										<span className="data-item-title text-base font-medium text-base-color">
											Uy plani:
										</span>
										<span className="data-item-value text-base">
											<Button
												type="button"
												variant="contained"
												className="!-mb-[10px] !w-[100px]"
												disabled={
													!(
														contractData?.homes?.plan &&
														contractData?.homes?.plan?.link
													)
												}
												onClick={() => setOpenHomeLayoutImageDialog(true)}
											>
												<i className="bi bi-image text-lg" />
											</Button>
										</span>
									</div>
								</div>

								<div className="contract-staff-details my-shadow-2 rounded-lg p-4 w-full mt-4 mb-1 border border-base-color bg-base-color-active-deprecated-f-30">
									<div>
										<span className="font-medium text-base-color">
											Shartnoma tuzgan mas'ul xodim:
										</span>{" "}
										{contractData?.staff?.name}
									</div>
								</div>

								<div className="mt-4 mb-6">
									{contractData?.id && (
										<Fragment>
											<div className="payment-history-title my-2 text-lg font-medium text-base-color">
												To'lov tarixi
											</div>
											<PaymentHistoryTable
												dataPath={`dictionary/paymentscontract/${contractData?.id}`}
											/>
										</Fragment>
									)}
								</div>
							</div>
							<div className="actions-box w-[280px] ml-6">
								<div className="p-4 my-shadow-2 rounded-lg flex items-center justify-center">
									<InfoTooltip
										arrow={true}
										placement="top"
										title="Ma'lumotlarni yangilash"
									>
										<Button variant="action" color="info" onClick={refetchFn}>
											<i className="bi bi-arrow-repeat" />
										</Button>
									</InfoTooltip>
									<SuccessTooltip
										arrow={true}
										placement="top"
										title="Shartnomani yuklab olish"
									>
										<Link
											to={`${process.env.REACT_APP_BACKEND_URL}/doc/${contractData?.id}`}
											className="no-underline"
										>
											<Button
												variant="action"
												color="success"
												className="!mx-4"
											>
												<i className="bi bi-download" />
											</Button>
										</Link>
									</SuccessTooltip>
								</div>

								<Button
									color="primary"
									variant="outlined"
									fullWidth
									className="!py-2 !my-4 !h-auto"
									onClick={() => setOpen(true)}
								>
									<div className="flex flex-col">
										<i className="bi bi-bookmarks text-2xl" />
										<span className="text-base">To'lov grafigi</span>
									</div>
								</Button>

								<div className="mt-4">
										{contractData?.status === "3" ? (
											<Button color="error" variant="contained" fullWidth>
												<span>Shartnoma bekor qilingan</span>
											</Button>
										) : contractData?.status === "2" ? (
											<Button color="error" variant="contained" fullWidth>
												<span>Shartnoma tugallangan</span>
											</Button>
										) : (
											<Button
												color="success"
												variant="outlined"
												fullWidth
												className="!py-2 !my-4 !h-auto"
												onClick={() => setOpenPaymentModal(true)}
											>
												<div className="flex flex-col">
													<i className="bi bi-currency-dollar text-2xl" />
													<span className="text-base">To'lov qilish</span>
												</div>
											</Button>
										)}
									</div>

								{contractData?.status === "2" && (
									<Button
										color="error"
										variant="contained"
										fullWidth
										className="!mb-4"
									>
										<span>Shartnoma tugallangan</span>
									</Button>
								)}

								{contractData?.status !== "3" &&
									user?.user?.role !== ROLE_TYPE_LIST.MANAGER.code && (
										<Button
											color="error"
											variant="outlined"
											fullWidth
											onClick={() => setCancellationOpen(true)}
										>
											<span>Bekor qilish</span>
										</Button>
									)}
							</div>
						</div>
					</div>
				</Fragment>
			)}
			{open && (
				<ContractPaymentListModal
					open={open}
					setOpen={setOpen}
					data={contractData}
				/>
			)}

			{openHomeLayoutImageDialog &&
				contractData?.homes?.plan &&
				contractData?.homes?.plan?.link && (
					<ImagePreviewDialog
						open={openHomeLayoutImageDialog}
						setOpen={setOpenHomeLayoutImageDialog}
						url={contractData?.homes?.plan?.link}
					/>
				)}

			{cancellationOpen && (
				<ContractCancellationModal
					open={cancellationOpen}
					setOpen={setCancellationOpen}
					data={contractData}
					refetch={refetchFn}
				/>
			)}

			{openPaymentModal && (
				<PaymentAddModal
					open={openPaymentModal}
					setOpen={setOpenPaymentModal}
					refetch={refetchFn}
					data={contractData}
				/>
			)}
		</div>
	)
}

export default ClientContract
